import type { QueuePayload } from './graph/queue.graphql'


type QueueItems = QueuePayload['currentUser']['data']['queue']['queueItems']


const modifyQueueItems = (queueItems: QueueItems): QueueModule.Items => {
  return queueItems.map((queueItem) => {
    const { month, year, statuses, paid, canceled, products, tracking } = queueItem

    const isSkipped = statuses.includes('SKIPPED')
    const isLocked = statuses.includes('LOCKED')
    const isShipped = statuses.includes('SHIPPED')
    const isPaused = statuses.includes('PAUSED')
    const isDelivered = tracking?.status === 'DELIVERED'

    const hasAvailablePlace = statuses.some((status) => (
      status === 'EMPTY'
      || status === 'PRODUCT'
      || status === 'FREE'
      || status === 'FREE_FOR_REFEREE'
      || status === 'FREE_FOR_REFERRAL'
    ))

    const isDisabled = !hasAvailablePlace && (isSkipped || isLocked || isShipped)
    const isEmpty = products.length === 0

    const modifiedProducts = statuses.map<QueueModule.Product>((status, index) => {
      const queueProduct = products[index]

      const isEmpty = !queueProduct?.tradingItem
      const isUnavailable = status === 'UNAVAILABLE'

      const isFree = (
        status === 'FREE'
        // free coupon and gift equal legacy redeemed status
        || status === 'FREE_GIFT'
        || status === 'FREE_COUPON'
        || status === 'FREE_FOR_REFEREE'
        || status === 'FREE_FOR_REFERRAL'
      )

      const isBlocked = (
        status === 'SKIPPED'
        || status === 'SHIPPED'
        || status === 'LOCKED'
      )

      // it's for correct work of moveProduct / removeProduct methods
      const isMovable = !isBlocked && !isEmpty

      return {
        ...queueProduct,
        status,
        stateOptions: {
          isEmpty,
          isFree,
          isBlocked,
          isMovable,
          isUnavailable,
          isDelivered,
        },
      }
    })

    return {
      month,
      year,
      statuses,
      tracking,
      products: modifiedProducts,
      stateOptions: {
        isSkipped,
        isLocked,
        isPaused,
        isShipped,
        isDelivered,
        isDisabled,
        isEmpty,
        isCancelled: canceled,
        isPaid: paid,
      },
    }
  })
}


export default modifyQueueItems
