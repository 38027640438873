import type { QueueProductStatus } from 'typings/graphql'
import type { QueuePayload } from './graph/queue.graphql'


export type QueueItems = QueuePayload['currentUser']['data']['queue']['queueItems']

const freeStatuses: QueueProductStatus[] = [ 'FREE', 'FREE_COUPON', 'FREE_FOR_REFEREE', 'FREE_FOR_REFERRAL', 'FREE_GIFT' ]

// remove empty months to keep only one of them
const filterEmptyQueueItems = (queueItems: QueueItems): QueueItems => {
  let emptyProductFound = false

  const result = queueItems.filter(({ statuses, products }) => {
    // if there are no products and user has some amount of free credits we should show the same amount of empty cells (see PF-544, scenario 14)
    let isFreeCreditFound = false

    // if cell is empty or free but without product and we can fill it
    const emptyProductsCount = statuses
      .filter((status, index) => {
        const isFreeCredit = (
          freeStatuses.includes(status)
          && !products[index]
        )

        isFreeCreditFound = isFreeCreditFound || isFreeCredit

        return (status === 'EMPTY' || isFreeCredit)
      })
      .length

    const isCompletelyEmpty = emptyProductsCount === statuses.length

    // we don't need to show all the skipped months if we already show empty cells in previous months
    const shouldHideSkipped = emptyProductFound && statuses[0] === 'SKIPPED'

    // if we already found item with empty product
    // and this empty item has no free credits
    // we can omit this empty item
    if (!isFreeCreditFound && emptyProductFound && isCompletelyEmpty || shouldHideSkipped) {
      return false
    }

    const isPartiallyEmpty = emptyProductsCount > 0

    if (isPartiallyEmpty) {
      emptyProductFound = true
    }

    return true
  })

  if (!emptyProductFound) {
    // if user filled the queue completely and backend doesn't return empty item,
    // we should add one
    const lastItem = result[result.length - 1]
    const nextMonth = lastItem.month === 12 ? 1 : lastItem.month + 1
    const nextYear = lastItem.month === 12 ? lastItem.year + 1 : lastItem.year

    result.push({
      month: nextMonth,
      year: nextYear,
      paid: false,
      canceled: false,
      tracking: null,
      statuses: lastItem.statuses.map(() => 'EMPTY'),
      products: [],
    })
  }

  return result
}


export default filterEmptyQueueItems
